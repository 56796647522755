import { AxiosInstance, AxiosRequestConfig, AxiosError, HttpStatusCode } from 'axios';
import { getToken, isCurrentUser } from './firebase.services.tsx';
import store from '../store';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';
import i18next from 'i18next';
import { ToastType } from '../enum/error.ts';
import { authActions } from '../store/auth/auth.reducers.tsx';

let axiosLabInstance: AxiosInstance;
/**
 * Add X-API-KEY on Axios Headers.
 * Add Firebase Token on Axios Headers.
 * @param config
 */
const onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  let headers = {
    ...config.headers,
    'x-api-key': import.meta.env.VITE_API_KEY_GCP_GATEWAY,
    __retryCount: 0
  };

  if (isCurrentUser()) {
    const token = await getToken();
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  return {
    ...config,
    headers: headers
  };
};

/**
 * Exponential back off if interceptor doesn't work.
 *
 * @param error
 */
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (error && 'config' in error && error.config) {
    error.config.headers.__retryCount = error.config.headers.__retryCount || 0;
    console.info('Retry request interception start : ' + error.config.headers.__retryCount);

    if (error.config.headers.__retryCount >= 3) {
      console.error('Retry request interception : ' + error.config.headers.__retryCount);
      return Promise.reject(error);
    }

    error.config.headers.__retryCount += 1;
    const backoff = new Promise((resolve) => {
      setTimeout(() => {
        console.info('Retry request interception succeed : ' + error?.config?.headers.__retryCount);
        resolve(error.config);
      }, 1);
    });
    return backoff.then(() => {
      return axiosLabInstance(error.config as AxiosRequestConfig);
    });
  }
  return Promise.reject(new AxiosError('An error occured', '500'));
};

/**
 * Intercept all responses with errors and signout if 403
 *
 * @param error
 */
const onResponseError = async (error: AxiosError) => {
  if (error.isAxiosError && error.response?.status === HttpStatusCode.Forbidden) {
    store.dispatch(
      feedbackActions.setToast({
        message: i18next.t('auth/user-token-expired', { ns: 'error' }),
        type: ToastType.DANGER
      })
    );
    store.dispatch(authActions.logout());
  }
};

/**
 * Define interceptors for Axios calls, globally.
 *
 * @param axiosInstance
 *   Global Axios Instance.
 */
export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosLabInstance = axiosInstance;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use((response) => response, onResponseError);
  return axiosInstance;
}
