import '@inovua/reactdatagrid-community/index.css';
import { Toast } from '@anatoscope/circlestorybook';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import './private-treatment-app.scss';
import { forceRefreshToken } from '../../services/firebase.services.tsx';
import { ToastType } from '../../enum/error.ts';

const PrivateTreatmentApp = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();

  const toast = useAppSelector(toastSelector);

  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    err?.message.includes('auth/user-token-expired')
      ? dispatch(
          feedbackActions.setToast({
            message: t('auth/user-token-expired', { ns: 'error' }),
            type: ToastType.DANGER
          })
        )
      : dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
  });

  return (
    <div className="private-treatment-app" data-cy="privateTreatmentApp">
      {toast && (
        <Toast
          message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
          onClose={() => {
            dispatch(feedbackActions.resetToast());
          }}
          autoHideDuration={toast.type === 'danger' ? 8000 : 5000}
          type={toast.type}
        />
      )}
      <Outlet />
    </div>
  );
};
export default PrivateTreatmentApp;
