// Order step displayed for dentist in treatment dashboard
export enum OrderStepEnum {
  SUBMITTING = 'submitting',
  VALIDATION = 'validation',
  MODELING = 'modeling',
  MANUFACTURING = 'manufacturing',
  DELIVERY = 'delivery',
  DELIVERED = 'delivered'
}

export enum OrderTreatmentStep {
  REFERENCE = 'reference',
  PLAN = 'plan',
  CUSTOM = 'custom',
  SCAN = 'scan',
  SUBMIT = 'submit'
}

export enum OrderTreatmentMode {
  CREATE = 'create',
  EDIT = 'edit'
}
